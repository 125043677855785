/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link, PrimaryButton } from "../../components/button/PrimaryButton";
import { Column, InvisibleButton, Line, Row } from "../../components/common";
import { Logo } from "../../components/common/Logo";
import device from "../../utils/device";
import Images from "./assets/images";
import { FreeTrialInfoModal } from "./components/FreeTrialInfoModal";
import { PhoneNumberModal } from "./components/PhoneNumberModal";
import { ReviewSlider } from "./components/ReviewSlider";
import { SuperBenefites } from "./components/SuperBenefits";
import { Footer } from "./Home.styled";

export const Home = () => {
  const navigate = useNavigate();
  const [infoModalOpen, setInfoModalOpen] = useState(false),
    [phoneModalOpen, setPhoneModalOpen] = useState(false);
  const isFreeTrialEnabled = process.env.REACT_APP_FREE_TRIAL_ENABLED === "true";

  const onPhoneModalClose = () => {
    setPhoneModalOpen(false);
    navigate("/plans");
  };

  return (
    <Column style={{ width: "100%", position: "relative" }}>
      <Column
        style={{
          paddingBottom: "6rem",
          alignSelf: "center",
          width: device.isMobile() ? "100%" : "40rem",
        }}
      >
        <div style={{ position: "relative", background: "#B8E5F6" }}>
          <img
            src={Images.TopBackground}
            alt="top-background"
            style={{
              marginTop: "4rem",
              objectFit: "cover",
              height: "20rem",
              width: "100%",
            }}
          />
          <span
            className="page-title"
            style={{
              position: "absolute",
              color: "#1E3163",
              fontWeight: 700,
              fontSize: "1.2rem",
              top: device.isMobile() ? "4rem" : "1rem",
              left: "0",
              width: "100%",
              padding: "0",
            }}
          >
            Kids learn 3 times faster with
            <br />
            Little Singham app{isFreeTrialEnabled ? " + activity box." : ""}
          </span>
        </div>
        <div style={{ marginTop: "-7rem" }}>
          <ReviewSlider />
        </div>
        <span className="page-title">
          Shape Your Child’s
          <br /> Personality & Academics
        </span>
        <img
          src={Images.AllCharacters}
          alt="all-characters"
          style={{
            width: "80%",
            height: "12rem",
            objectFit: "contain",
            alignSelf: "center",
            marginBottom: "2rem",
          }}
        />
        <span className="page-title">Why Choose {isFreeTrialEnabled ? "Super" : "Us"}?</span>
        <div
          style={{
            width: device.isMobile() ? "100%" : "40rem",
            alignSelf: "center",
          }}
        >
          <SuperBenefites />
        </div>
        <Line style={{ width: "100%", margin: "1rem 0" }} />
        <span className="page-title">{isFreeTrialEnabled ? "FREE 14 Day Trial" : "100% refund guarantee"}</span>
        <span
          style={{
            color: "#FF7700",
            textAlign: "center",
            fontWeight: 500,
            cursor: "pointer",
            marginTop: "0.2rem",
            marginBottom: "2rem",
          }}
          onClick={() => setInfoModalOpen(true)}
        >
          How this works?
        </span>
        <Column style={{ margin: "1rem 0" }}>
          <span
            style={{
              fontWeight: "900",
              fontSize: "1.3rem",
              textAlign: "center",
              width: device.isMobile() ? "auto" : "50rem",
            }}
          >
            Featured In
          </span>
          <Row style={{ marginTop: "1rem" }}>
            <img
              src={Images.ForbesLogo}
              alt="forbes"
              style={{
                height: "3rem",
                width: "5rem",
                objectFit: "contain",
                margin: "0 1rem",
              }}
            />
            <img
              src={Images.TimesNowLogo}
              alt="forbes"
              style={{
                height: "3rem",
                width: "5rem",
                objectFit: "contain",
                margin: "0 1rem",
              }}
            />
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <img
              src={Images.EconomicTimesLogo}
              alt="forbes"
              style={{
                width: "30%",
                height: "2rem",
                objectFit: "contain",
                margin: "0 0.5rem",
              }}
            />
            <img
              src={Images.YourStoryLogo}
              alt="forbes"
              style={{
                width: "20%",
                height: "2rem",
                objectFit: "contain",
                margin: "0 0.5rem",
              }}
            />
            <img
              src={Images.ThePrintLogo}
              alt="forbes"
              style={{
                width: "30%",
                height: "2rem",
                objectFit: "contain",
                margin: "0 0.5rem",
              }}
            />
          </Row>
        </Column>
        <Line style={{ width: "85%" }} />
        <Row style={{ marginTop: "2rem", marginBottom: "1rem" }}>
          <img src={Images.PlayStoreLogo} style={{ width: "3rem", height: "3rem", marginRight: "0.5rem" }} />
          <Column>
            <img src={Images.AppRating} style={{ height: "1rem", width: "6rem" }} />
            <span>4.5 Star Rating</span>
          </Column>
        </Row>
        <span style={{ textAlign: "center", padding: "0 2rem" }}>
          7 Million+ parents trust us with their child's' Customised learning.
        </span>
        <Link
          onClick={() => {
            document.getElementById("continue_wo_trial")?.click();
            window.open("https://creativegalileo.sng.link/B3dg4/jlai/f0u0", "_blank");
          }}
          style={{ marginTop: "1rem", alignSelf: "center" }}
        >
          Continue Without Trial
        </Link>
      </Column>
      <Footer>
        <PrimaryButton
          style={{ marginBottom: "1rem" }}
          onClick={() => {
            document.getElementById("start_trial")?.click();
            // setPhoneModalOpen(true);
            navigate("/plans");
          }}
        >
          {isFreeTrialEnabled ? (
            <>
              <span>Start Your 14 Day Free Trial</span>
              <div style={{ fontSize: "0.8rem", fontWeight: "300" }}>and cancel at any moment</div>
            </>
          ) : (
            <>
              <span>Subscribe Now</span>
              <div style={{ fontSize: "0.8rem", fontWeight: "300" }}>100% refund guarantee</div>
            </>
          )}
        </PrimaryButton>
      </Footer>
      <Logo style={{ position: "absolute", top: "0" }} />
      <InvisibleButton id="continue_wo_trial" />
      <InvisibleButton id="start_trial" />
      <FreeTrialInfoModal open={infoModalOpen} onClose={() => setInfoModalOpen(false)} />
      <PhoneNumberModal open={phoneModalOpen} onClose={onPhoneModalClose} />
    </Column>
  );
};
