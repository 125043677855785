import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Oval } from "react-loader-spinner";
import ReactModal from "react-modal";
import { useLocation } from "react-router-dom";
import CommonImages from "../../assets/images";
import { Link, PrimaryButton } from "../../components/button/PrimaryButton";
import { Column, InvisibleButton, Row } from "../../components/common";
import { Logo } from "../../components/common/Logo";
import { modalStyles } from "../../components/modal/Modal";
import Colors from "../../theme/colors";
import device from "../../utils/device";
import { PlanType } from "../Plans/plansConfig";
import Images from "./images";
import { InputBox } from "./Misc.styled";

export const ThankYou = () => {
  const { state } = useLocation(),
    plan = (state as any)?.plan as PlanType,
    paymentId = (state as any)?.paymentId as string,
    { register, setValue, getValues, reset } = useForm(),
    [showModal, setShowModal] = useState(false),
    [isLoading, setIsLoading] = useState(false),
    formRef = useRef<HTMLFormElement>(null);

  const onSubmit = () => {
    const data = getValues();
    if (!formRef.current?.checkValidity()) {
      formRef.current?.reportValidity();
      return;
    }
    document.getElementById("submit")?.click();
    setIsLoading(true);
    axios
      .post("https://sheet.best/api/sheets/de14ffe1-bc70-4b31-9f12-2c45f9aa5d6e", data)
      .then(() => {
        setIsLoading(false);
        setShowModal(true);
        window.open("https://creativegalileo.sng.link/B3dg4/jlai/f0u0", "_blank");
        reset({ "Phone Number": "+" + (state as any)?.mobile || "" });
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (state && (state as any).mobile) {
      setValue("Phone Number", "+" + (state as any).mobile);
    }
  }, [state, setValue]);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Column style={{ width: "100%" }}>
      <Logo />
      <Column style={{ padding: "1rem 0.5rem", width: device.isMobile() ? "auto" : "40rem", alignSelf: "center" }}>
        <Column
          style={{
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
            background: "white",
            borderRadius: "0.5rem",
            padding: device.isMobile() ? "1rem" : "2rem",
            alignItems: "center",
            textAlign: "center",
            lineHeight: "1.3rem",
            margin: "1rem",
          }}
        >
          <span className="page-title">Welcome to Little Singham</span>
          <img src={Images.Police} alt="police" style={{ width: "10rem", marginTop: "1rem" }} />
          <span style={{ margin: "1rem 0", fontSize: "1.1rem", fontWeight: 700 }}>Thank you for your payment.</span>
          <span>Your Transaction Id is:</span>
          <span>
            <b>{paymentId}</b>
          </span>
          <span style={{ marginTop: "1rem" }}>
            To activate your{" "}
            <b>“{plan === "lifetime" ? "Lifetime" : plan === "annual" ? "Annual" : "Basic Annual"}” plan</b>, download
            the Little Singham app from play store & login using your registered mobile number.
          </span>
          <div style={{ margin: "1rem 0" }}>
            {plan !== "basic-annual" ? (
              <span>
                <b>Super Activity Box</b> will be delivered within 7 working days. For home delivery, please provide
                your details:
              </span>
            ) : (
              <span>Please provide us with the following information for a better app experience</span>
            )}
          </div>
          <div>
            <span>Read our refund Policy</span>{" "}
            <Link style={{ color: Colors.orange }} onClick={() => window.open("/refund-policy")}>
              here
            </Link>
          </div>
          <form style={{ width: "100%" }} ref={formRef}>
            <InputBox>
              <span>Parent Name*:</span>
              <input {...register("Parent Name")} required />
            </InputBox>
            <InputBox>
              <span>Mobile Number*:</span>
              <input {...register("Phone Number")} required disabled />
            </InputBox>
            <InputBox>
              <span>Email (optional):</span>
              <input {...register("Email")} />
            </InputBox>
            <InputBox>
              <span>Child's Name*:</span>
              <input {...register("Child Name")} required={true} />
            </InputBox>
            <InputBox>
              <span>Child's Gender:</span>
              <Row style={{ justifyContent: "flex-start", alignItems: "baseline", padding: "0.5rem 0" }}>
                <label style={{ marginRight: "1rem" }}>
                  <input
                    {...register("Gender")}
                    type="radio"
                    name="gender"
                    value="Girl"
                    id="girl"
                    onChange={(e: any) => setValue("Gender", e.target.value)}
                  />
                  Girl
                </label>
                <label>
                  <input
                    {...register("Gender")}
                    type="radio"
                    name="gender"
                    value="Boy"
                    id="boy"
                    onChange={(e: any) => setValue("Gender", e.target.value)}
                  />
                  Boy
                </label>
              </Row>
            </InputBox>
            <InputBox>
              <span>Child's DOB*:</span>
              <input
                {...register("Child Birthdate")}
                type="date"
                required={true}
                max={new Date().toLocaleDateString("en-CA")}
              />
            </InputBox>
            <InputBox>
              <span>Pincode:</span>
              <input {...register("Pincode")} />
            </InputBox>
            <InputBox>
              <span>Address</span>
              <textarea {...register("Address")} style={{ height: "4rem" }} required={plan !== "basic-annual"} />
            </InputBox>
          </form>

          <PrimaryButton style={{ height: "2.5rem", marginTop: "1rem" }} onClick={onSubmit}>
            Submit & Go to Our App
          </PrimaryButton>
          <div style={{ marginTop: "1rem" }}>
            <span>
              For customer support,{" "}
              <a href="mailto:payments@creativegalileo.com" style={{ color: Colors.orange }}>
                Email
              </a>
              {" or "}
              <a href="https://wa.me/7304941106" style={{ color: Colors.orange }}>
                Whatsapp us
              </a>
            </span>
          </div>
        </Column>
      </Column>
      <ReactModal isOpen={showModal} style={modalStyles} onRequestClose={() => setShowModal(false)}>
        <div>Thank you for submitting your information!</div>
        <img
          src={CommonImages.CloseButton}
          style={{ width: "1rem", position: "absolute", right: "0.5rem", top: "0.5rem" }}
          alt="close"
          onClick={() => setShowModal(false)}
        />
      </ReactModal>
      <ReactModal isOpen={isLoading} style={modalStyles}>
        <Row style={{ justifyContent: "flex-start", alignItems: "center" }}>
          <Oval height={30} width={30} color={Colors.orange} strokeWidth={5} />{" "}
          <span style={{ marginLeft: "1rem" }}>Please wait...</span>
        </Row>
      </ReactModal>
      <InvisibleButton id="submit" />
    </Column>
  );
};
