/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import CommonImages from "../../assets/images";
import { Link } from "../../components/button/PrimaryButton";
import { Bullet, Caret, Column, InvisibleButton, Row, Rupee } from "../../components/common";
import { Logo } from "../../components/common/Logo";
import { modalStyles } from "../../components/modal/Modal";
import Colors from "../../theme/colors";
import device from "../../utils/device";
import PlanImages from "./assets/images";
import { PlanCard } from "./components/PlanCard";
import { Checkbox, OfferCountdownContainer } from "./Plans.styled";
import { allPlans, PlanConfig } from "./plansConfig";

const membershipBenefits = [
  { title: "Screen time control" },
  { title: "Math and English curriculum" },
  { title: "Super box home delivery", subTitle: "(Higher Plans Only)" },
  { title: "Real-time progress report" },
  { title: "Unlimited videos, games and puzzles" },
  { title: "Learn 50+ professions" },
  { title: "Personalized avatar profile" },
  { title: "Early access to new characters" },
  { title: "Parent-child fun quizzes" },
];

const offerDate = new Date("2022-08-15");

export interface RemainingTime {
  hourParts: string[];
  minParts: string[];
  secParts: string[];
}

const defaultRemainingTime: RemainingTime = {
  hourParts: ["0", "0"],
  minParts: ["0", "0"],
  secParts: ["0", "0"],
};

export const Plans = () => {
  const [selectedPlan, setSelectedPlan] = useState<PlanConfig | null>(null),
    [error, setError] = useState(""),
    [benefitsExpanded, setBenefitsExpanded] = useState(false),
    [phoneNumber, setPhoneNumber] = useState(""),
    [remainingTime, setRemainingTime] = useState<RemainingTime>(defaultRemainingTime),
    [termsAccepted, setTermsAccepted] = useState(true);

  useEffect(() => window.scrollTo(0, 0), []);

  const updateRemainingTime = () => {
    // @ts-ignore: Unreachable code error
    const totalSeconds = (offerDate - new Date()) / 1000;
    if (totalSeconds < 0) {
      setRemainingTime(defaultRemainingTime);
      return;
    }
    const hours = Math.floor(totalSeconds / 36e2);
    const remainingSeconds = totalSeconds - hours * 36e2;
    const mins = Math.floor(remainingSeconds / 60);
    const secs = Math.floor(remainingSeconds - mins * 60);
    setRemainingTime({
      hourParts: hours.toString().padStart(2, "0").split(""),
      minParts: mins.toString().padStart(2, "0").split(""),
      secParts: secs.toString().padStart(2, "0").split(""),
    });
    setTimeout(() => updateRemainingTime(), 1000);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    updateRemainingTime();
    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (phoneNumber.length === 12) {
      document.getElementById("mobile_no_entered")?.click();
      axios.post("https://sheet.best/api/sheets/02f90cf4-04d4-45e6-ada5-d2a6aaa8a982", {
        "Phone Number": "+" + phoneNumber,
        Pricing: selectedPlan?.price.toString(),
        Date: new Date().toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  const onCardClick = (plan: PlanConfig) => {
    if (selectedPlan?.type === plan.type) {
      setSelectedPlan(null);
    } else {
      setSelectedPlan(plan);
    }
  };

  return (
    <Column style={{ width: "100%", position: "relative" }}>
      <Logo />
      <img
        src={PlanImages.PlansBG}
        style={{ position: "absolute", width: "100%", top: 0, zIndex: -1, maxHeight: "100%" }}
      />
      <Column
        style={{
          padding: "0 1rem 7rem 1rem",
          width: device.isMobile() ? "auto" : "40rem",
          alignSelf: "center",
          marginTop: "1rem",
        }}
      >
        <Column
          style={{
            alignItems: "center",
            background: Colors.pink,
            color: "white",
            padding: "0.5rem",
            margin: "0 1rem",
            borderRadius: "0.5rem 0.5rem 0 0",
          }}
        >
          <span>August Flash Sale</span>
          <span style={{ fontWeight: 700, fontSize: "1rem", marginTop: "0.2rem" }}>
            Discount coupons worth <Rupee /> 300
          </span>
        </Column>
        <OfferCountdownContainer>
          <span style={{ marginRight: "1rem", fontWeight: 500 }}>Offer valid for:</span>
          {[
            { title: "Hours", parts: remainingTime.hourParts },
            { title: "Minutes", parts: remainingTime.minParts },
            { title: "Seconds", parts: remainingTime.secParts },
          ].map((config) => (
            <Column className="time-column" key={config.title}>
              <span className="time-label">{config.title}</span>
              <Row>
                {config.parts.map((part, index) => (
                  <div className="time-value" key={index}>
                    {part}
                  </div>
                ))}
              </Row>
            </Column>
          ))}
        </OfferCountdownContainer>
        <Column
          style={{
            background: "white",
            padding: "1rem",
            borderRadius: "1rem",
            boxShadow: "0 0 1rem #dddddd",
            marginTop: "2rem",
          }}
        >
          <span className="page-title" style={{ padding: "0", alignSelf: "flex-start", marginBottom: "0.5rem" }}>
            Your Membership Includes:
          </span>
          {membershipBenefits.slice(0, benefitsExpanded ? membershipBenefits.length : 4).map((benefit, index) => (
            <Row
              key={benefit.title}
              style={{ justifyContent: "flex-start", alignItems: "flex-start", margin: "0.1rem 0" }}
            >
              <Bullet color="#D8F3FF" size={0.8} />
              <Column style={{ marginTop: "0.2rem" }}>
                <span style={{ fontWeight: index === 2 ? 500 : 400 }}>{benefit.title}</span>
                <span style={{ color: "#6F6571", fontSize: "0.8rem", lineHeight: "0.9rem" }}>{benefit.subTitle}</span>
              </Column>
            </Row>
          ))}
          <Row
            style={{ marginTop: "0.5rem", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              document?.getElementById("view_more")?.click();
              setBenefitsExpanded(!benefitsExpanded);
            }}
          >
            <span style={{ color: Colors.orange, fontWeight: 500 }}>View {benefitsExpanded ? "Less" : "More"}</span>
            <Caret expanded={benefitsExpanded} />
          </Row>
        </Column>
        <span style={{ marginTop: "2rem", marginBottom: "1rem", fontSize: "1.2rem", fontWeight: 500 }}>
          Choose A Plan
        </span>
        {allPlans.map((plan) => (
          <PlanCard
            key={plan.type}
            className={`plan_${plan.price}`}
            selected={selectedPlan?.type === plan.type}
            config={plan}
            phoneNumber={phoneNumber}
            onPhoneNumberChanged={setPhoneNumber}
            onClick={() => onCardClick(plan)}
            termsAccepted={termsAccepted}
          />
        ))}
        <Row style={{ alignItems: "center", padding: "1rem" }}>
          <Checkbox type="checkbox" checked={termsAccepted} onChange={(e: any) => setTermsAccepted(e.target.checked)} />
          <div style={{ marginLeft: "0.5rem" }}>
            <span style={{ marginRight: "0.3rem" }}>I agree with the </span>
            <Link onClick={() => window.open("/terms")}> Terms {"&"} Conditions</Link>
            <span style={{ margin: "0 0.3rem" }}>&</span>
            <Link onClick={() => window.open("/privacy-policy")}> Privacy Policy</Link>
          </div>
        </Row>
      </Column>
      <ReactModal isOpen={error.length > 0} style={modalStyles} onRequestClose={() => setError("")}>
        <div dangerouslySetInnerHTML={{ __html: error }} style={{ color: "red" }}></div>
        <img
          src={CommonImages.CloseButton}
          style={{ width: "1rem", position: "absolute", right: "0.5rem", top: "0.5rem" }}
          alt="close"
          onClick={() => setError("")}
        />
      </ReactModal>
      <InvisibleButton id="view_more" />
      <InvisibleButton id="mobile_no_entered" />
    </Column>
  );
};
