/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import { useState } from "react";
import ReactModal from "react-modal";
import "react-phone-input-2/lib/style.css";
import CommonImages from "../../../assets/images";
import { PrimaryButton } from "../../../components/button/PrimaryButton";
import { Column, Row } from "../../../components/common";
import { PhoneNumberInput } from "../../../components/common/PhoneNumberInput";
import Colors from "../../../theme/colors";
import device from "../../../utils/device";
import { Checkbox } from "../../Plans/Plans.styled";

export const PhoneNumberModal: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const [phoneNumber, onPhoneNumberChanged] = useState(""),
    [termsAccepted, setTermsAccepted] = useState(true),
    [error, setError] = useState("");

  const onConfirmClicked = () => {
    if (phoneNumber.length !== 12) {
      setError("Please enter valid phone number");
    } else {
      setError("");
      axios.post("https://sheet.best/api/sheets/888e75a3-d80c-4333-a3c8-16fb2c3c7a1c", {
        "Mobile Number": "+" + phoneNumber,
        "Whatsapp consent": termsAccepted ? "Yes" : "No",
        Timestamp: new Date().toString(),
      });
      onClose();
    }
  };

  return (
    <ReactModal
      isOpen={open}
      style={{
        content: {
          width: device.isMobile() ? "80%" : "30rem",
          transform: "translate(-50%, 0)",
          border: `1px solid ${Colors.orange}`,
          inset: "auto 50% 0",
          borderRadius: "1rem",
          background: "#FFF1E7",
          marginBottom: "1rem",
        },
        overlay: {
          background: "#00000099",
          zIndex: 100,
        },
      }}
    >
      <Column style={{ alignItems: "center" }}>
        <img src={CommonImages.CloseButton} style={{ width: "1rem", alignSelf: "end" }} onClick={onClose} />
        <span style={{ textAlign: "center", fontWeight: 400, marginBottom: "1rem" }}>
          Want to know more about our plans?
          <br /> Help us with your contact details
        </span>
        <span style={{ marginBottom: "0.5rem", alignSelf: "start" }}>
          <b>Mobile Number:</b>
        </span>
        <PhoneNumberInput phoneNumber={phoneNumber} onPhoneNumberChanged={onPhoneNumberChanged} />
        <span style={{ color: "red", marginTop: "0.5rem" }}>{error}</span>
        <PrimaryButton style={{ marginTop: "2rem", marginBottom: "1rem" }} onClick={onConfirmClicked}>
          Confirm
        </PrimaryButton>
        <Row style={{ alignItems: "center", padding: "1rem" }}>
          <Checkbox
            style={{ width: "2rem" }}
            type="checkbox"
            checked={termsAccepted}
            onChange={(e: any) => setTermsAccepted(e.target.checked)}
          />
          <div style={{ marginLeft: "0.5rem" }}>
            <span style={{ marginRight: "0.3rem", fontSize: "0.8rem", color: "#6F6571" }}>
              I allow Creative Galileo to send me notifications over Whatsapp
            </span>
          </div>
        </Row>
      </Column>
    </ReactModal>
  );
};
